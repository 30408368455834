import React, { useCallback, useState } from 'react';

import { BaseComponentProps, Tooltip, Typography } from '@srnade/component-ui';

import { useTranslation } from '@srnade/web/i18n/client';
import { PanelContent, DownloadAudioButton } from '@srnade/web/components';
import { BonusContent as BonusContentType } from '@srnade/web/__generated__/graphql';
import { ProductBonusContent } from '../ProductBonusContent';
import { ViewNow } from '../ViewNow/ViewNow.component';

interface BonusContentProps {
    locked: boolean;
    bonusContents: BonusContentType[];
    onGetDownloadUrl?: (assetId: string) => Promise<string | undefined>;
    showStreamDownload?: boolean;
}

export const BonusContent: React.FC<BonusContentProps & BaseComponentProps> = ({
    className,
    bonusContents = [],
    locked = true, // override to lock all content
    onGetDownloadUrl,
    showStreamDownload = false,
}) => {
    const { t } = useTranslation('components', { keyPrefix: 'bonusContent' });
    const [loadingStates, setLoadingStates] = useState<{ [assetId: string]: boolean }>({});

    const setLoadingState = (assetId: string, isLoading: boolean) => {
        setLoadingStates((prevLoadingStates) => ({
            ...prevLoadingStates,
            [assetId]: isLoading,
        }));
    };

    // This will trigger <a /> that lives in <ProductBonusContent />
    const triggerDownload = (downloadUrl: string) => {
        const anchorTag = document.getElementById('downloadBonusContent') as HTMLAnchorElement;
        if (anchorTag) {
            anchorTag.href = downloadUrl;
            anchorTag.click();
        }
    };

    const handleOnDownload = async (assetId: string) => {
        if (!onGetDownloadUrl) {
            return;
        }

        setLoadingState(assetId, true);

        const downloadUrl = await onGetDownloadUrl(assetId);

        setLoadingState(assetId, false);

        if (downloadUrl) {
            triggerDownload(downloadUrl);
        }
    };

    const isConsumable = useCallback((bonusContent: BonusContentType): boolean => {
        // return bonusContent.streamable === true || bonusContent.downloadable === true;
        // TODO Update this to also include streamable later
        return bonusContent.downloadable === true && bonusContent.active === true;
    }, []);

    return (
        <div className={className} data-testid="bonus-content">
            <div className="flex flex-col mt-[4rem]">
                <div className="flex flex-row gap-4 justify-between items-center">
                    <div className="flex items-center">
                        <Typography variant="bodyBold">
                            {t('title')} {(bonusContents || []).length > 0 && <>({bonusContents.length})</>}
                        </Typography>
                        <div className="relative">
                            <div className="ml-[0.3rem] absolute bottom-[0.5rem]">
                                <Tooltip active="aboveLeft">{t('toolTip')}</Tooltip>
                            </div>
                        </div>
                    </div>
                    {showStreamDownload === true && (
                        <div className="flex flex-col">
                            <div className="flex flex-col w-full items-end max-w-[18rem]">
                                <DownloadAudioButton canDownloadAudio={false} />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <PanelContent className="max-h-[38rem]" totalItems={bonusContents.length || 0}>
                {bonusContents.map((bonusContent) => (
                    <ProductBonusContent
                        key={bonusContent.assetId}
                        bonusContent={bonusContent}
                        onDownload={handleOnDownload}
                        isLoading={loadingStates[bonusContent.assetId!] || false}
                        locked={locked || !isConsumable(bonusContent)}
                    />
                ))}
            </PanelContent>
            <a id="downloadBonusContent" className="invisible" download />
        </div>
    );
};
