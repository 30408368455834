export const firstNameValidator = (yup: any) => {
    return yup
        .string()
        .matches(
            /^(?=.{2,50}$)[a-zA-Z]+(?:[a-zA-Z]+)*$/g,
            'Name must be between 2 and 50 characters, contain only letters, and one name',
        )
        .required('Required');
};

export const lastNameValidator = (yup: any) => {
    return yup
        .string()
        .matches(
            /^(?=.{2,50}$)[a-zA-Z]+(?:[-\s][a-zA-Z]+)*$/g,
            'Last Name must be between 2 and 50 characters, contain only letters and hyphens, and can include for a multi-word last name.',
        )
        .required('Required');
};

export const passwordValidator = (yup: any) => {
    // At least 8 characters in length
    // Contain at least 3 of the following 4 types of characters:
    //    Lower case letters (a-z)
    //    Upper case letters (A-Z)
    //    Numbers (i.e. 0-9)
    //    Special characters (e.g. !@#$%^&*)
    return (
        yup
            .string()
            .min(5, 'Must consist of at least 8 characters.')
            .matches(/[a-z]+/, 'Must contain lower case letters.')
            .matches(/[A-Z]+/, 'Must contain upper case letters.')
            .matches(/[0-9]+/, 'Must contain numbers.')
            .matches(/[ !"#$%&'()*\+,\-.\/:;<=>?@\[\\\]^_`{|}~]+/, 'Must contain special characters.')
            // .matches(
            //     /^(?=(.*[a-z]){3,})(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/,
            //     'Must consist of at least 8 characters and contain uppercase, lowercase and special characters (e.g. !@#$%^&*), as well as a number.',
            // )
            .required('Required')
    );
};

export const confirmPasswordValidator = (yup: any) => {
    return yup
        .string()
        .required('Required')
        .oneOf([yup.ref('password'), null], 'Passwords must match');
};

export const usernameValidator = (yup: any) => {
    return yup
        .string()
        .matches(
            /^(?=.{3,30}$)(?=[A-Za-z0-9])[A-Za-z0-9]+(?:\.[A-Za-z0-9]+)*$/gi,
            'Username must be between 3 and 30 characters, contain only letters, numbers, a period (.) and not start or end with a period.',
        )
        .required('Required');
};

export const ethAddressValidator = (yup: any) => {
    return yup
        .string()
        .matches(/^0x[a-fA-F0-9]{40}$/, 'Please enter a valid address')
        .required('Required');
};

export const isrcValidator = (yup: any) => {
    return yup
        .string()
        .matches(/\b[A-Z0-9]{12}\b/g, 'IRSC must be exactly 12 characters, with only capital letters and numbers.');
};

export const upcValidator = (yup: any) => {
    return yup.string().matches(/\b[0-9]{12}\b/g, 'UPC must be 12 decimal digits');
};

export const instagramUrlValidator = (yup: any) => {
    return yup
        .string()
        .matches(
            /(https):\/\/www.instagram.com\//gi,
            'Instagram URL must start with (eg. https://www.instagram.com/) and then your username.',
        )
        .required('Required');
};
