import clsx from 'clsx';
import { format } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import { addUsernamePrefix } from '@srnade/component-ui';
import { Typography } from '@srnade/component-ui';

import { FormatType } from '@srnade/web/__generated__/graphql';
import { useAuth } from '@srnade/web/auth';
import {
    Avatar,
    Container,
    ImageTile,
    Link,
    ProductMusicDetails,
    ProductOwnedBy,
    ProductTitle,
    ProductTypeInfo,
    StreamPlayer,
    USPBanner,
} from '@srnade/web/components';
import { BreakpointWidth } from '@srnade/web/const';
import { useTranslation } from '@srnade/web/i18n/client';
import { useAppSelector } from '@srnade/web/store/global';
import {
    EditionFormatStatus,
    selectEditionFormat,
    selectEditionFormatStatus,
} from '@srnade/web/store/reducers/edition-format';
import { selectPlayerArtist, selectPlayerArtworkUrl, selectPlayerIsVisible } from '@srnade/web/store/reducers/player';
import { ProductCountdown } from '../ProductCountdown';
import { EditionCollectibleDetails } from './EditionCollectibleDetails.component';
import styles from './EditionDetails.module.scss';
import { BONUS_CONTENT_INDEX, EditionPressingDetails, TRACKS_INDEX } from './EditionPressingDetails.component';

type GetCssClassesForProductDetailsFn = (width: number) => string;

const getCssClassesForProductDetails: GetCssClassesForProductDetailsFn = (width: number): string => {
    if (BreakpointWidth.Laptop <= width) {
        return 'hidden w-full laptop:grid laptop:mt-[0rem] laptop:col-span-1';
    }

    if (BreakpointWidth.Tablet <= width) {
        return 'hidden w-full tablet:px-0 tablet:grid tablet:mt-[4rem] laptop:hidden';
    }

    return 'w-full mt-[4rem] px-[2rem] tablet:hidden';
};

export const EditionDetails: React.FC = () => {
    const { t: tEditionPage } = useTranslation('product-page');
    const { width } = useWindowSize();
    const cssClassesForProductTile = getCssClassesForProductDetails(width);

    const { activeAccount } = useAuth();
    const [countdownDateTime, setCountdownDateTime] = useState<Date | null>(null);
    const [countdownTimeZone, setCountdownTimeZone] = useState<string | null>(null);
    const [countdownText, setCountdownText] = useState<string | null>(null);

    const editionFormat = useAppSelector(selectEditionFormat);
    const status: EditionFormatStatus = useAppSelector(selectEditionFormatStatus);
    const showStreamPlayer = useAppSelector(selectPlayerIsVisible);
    const artworkUrl = useAppSelector(selectPlayerArtworkUrl);
    const artist = useAppSelector(selectPlayerArtist);

    // Countdown timer
    useEffect(() => {
        if (!editionFormat) {
            return;
        }

        const now = new Date();

        // Check if the product is released and has a planned release date
        if (
            editionFormat.plannedReleaseDate &&
            editionFormat.plannedReleaseDateTimezone &&
            new Date(editionFormat.plannedReleaseDate) > now
        ) {
            const plannedReleaseDate = new Date(editionFormat.plannedReleaseDate);
            setCountdownDateTime(plannedReleaseDate);
            setCountdownTimeZone(editionFormat.plannedReleaseDateTimezone);
            setCountdownText(format(plannedReleaseDate, "'Releases' dd MMMM, yyyy 'at' h:mma"));
            return;
        }

        // remove countdown if no date is available
        setCountdownDateTime(null);
        setCountdownTimeZone(null);
        setCountdownText(null);
    }, [editionFormat?.plannedReleaseDate, editionFormat?.plannedReleaseDateTimezone, editionFormat]);

    const [currentPressingTabIndex, setCurrentPressingTabIndex] = useState(TRACKS_INDEX);

    const onSelectedTabChange = (tabIndex: number) => {
        setCurrentPressingTabIndex(tabIndex);
    };

    // TODO handle status Failed, redirect to the 404 page
    if (!status || status !== 'Idle' || !editionFormat) {
        return null;
    }

    const {
        collectible,
        pressing,
        formatInstanceId,
        formatSubType,
        formatType,
        limited,
        editionSize,
        editionSequence,
        title,
        slug,
        recordLabel,
        preOrder,
        productStatus,
        behindTheMusic,
        termsAndConditionsUrl,
        editionOwner,
    } = editionFormat;
    const editionSizeOrUndefined = editionSize === null ? undefined : editionSize;
    const formatData = { formatType, formatSubType, limited, editionSize: editionSizeOrUndefined };

    const editionOwnerIsMe = editionOwner && editionOwner.username == activeAccount?.username;

    const isCollectible = formatType === FormatType.DIGITAL_COLLECTIBLE;
    const hasArtwork = editionFormat?.artworkUrl || editionFormat?.title;
    const display = {
        showPressing: !isCollectible && !!pressing,
        showCollectible: editionOwnerIsMe && isCollectible && !!collectible,
        showBonusContent: !isCollectible && pressing?.bonusContent?.length ? pressing.bonusContent.length > 0 : false,
        showArtwork: isCollectible ? !editionOwnerIsMe && hasArtwork : hasArtwork,
        showCountdown: countdownDateTime && countdownTimeZone && countdownText,
        behindTitle: collectible ? tEditionPage('collectibleTitle') : tEditionPage('pressingTitle'),
    };

    // Stream player play/skip event - re-focus on Tracks tab
    const focusOnTrackTab = () => {
        if (currentPressingTabIndex === BONUS_CONTENT_INDEX) {
            setCurrentPressingTabIndex(TRACKS_INDEX);
        }
    };

    return (
        <div>
            {showStreamPlayer && (
                <div>
                    {editionOwnerIsMe && (
                        <div className={styles.stream_player_wrapper}>
                            <StreamPlayer
                                formatInstanceId={formatInstanceId}
                                onTrackPlayPause={() => {
                                    focusOnTrackTab();
                                }}
                                onTrackChange={() => {
                                    focusOnTrackTab();
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
            <Container className="grid gap-x-[7.2rem] justify-center justify-items-end pt-[0.8rem] mobile:px-0 tablet:justify-content-start tablet:grid-cols-[54.7rem] laptop:grid-cols-[repeat(2,50.5rem)]">
                <div
                    className={clsx('w-full h-full laptop:col-span-1', {
                        'laptop:w-[45rem]': showStreamPlayer,
                    })}
                    data-testid="view-edition"
                >
                    <div className="relative">
                        {display.showArtwork && <ImageTile src={artworkUrl || ''} alt={title || ''} />}
                        {editionSequence && (
                            <div className="bg-white absolute top-[10px] left-[10px] px-[10px] py-[6px] rounded-md text-[1.2rem] leading-none drop-shadow-md">
                                <ProductOwnedBy
                                    productStatus={productStatus}
                                    preOrder={preOrder}
                                    editionSequence={editionSequence}
                                    editionOwner={editionOwner}
                                    activeAccount={activeAccount}
                                />
                            </div>
                        )}
                    </div>

                    {display.showCollectible && <EditionCollectibleDetails editionFormat={editionFormat} />}
                </div>
                <div className={cssClassesForProductTile}>
                    <div data-gtm-id="edition_details">
                        {display.showCountdown && (
                            <div className="mb-[4rem]">
                                <ProductCountdown
                                    dateTime={countdownDateTime!}
                                    timeZone={countdownTimeZone!}
                                    text={countdownText!}
                                />
                            </div>
                        )}

                        {display.showPressing && (
                            <EditionPressingDetails
                                editionFormat={editionFormat}
                                selectedTabIndex={currentPressingTabIndex}
                                onSelectedTabChange={onSelectedTabChange}
                            />
                        )}

                        <div className="my-[6rem] border-b-[0.1rem] border-slate-200" />

                        <div className="flex items-center justify-between">
                            <div className="flex items-center" data-gtm-id="edition_details_artist">
                                <Link href={`/${addUsernamePrefix(artist.username)}`} passHref legacyBehavior>
                                    <span className="cursor-pointer">
                                        <Avatar src={artist?.profilePicUrl} name={artist.name} size="small" />
                                    </span>
                                </Link>
                                <Link href={`/${addUsernamePrefix(artist.username)}`} passHref legacyBehavior>
                                    <Typography className="ml-[1rem] hover:underline" variant="body">
                                        {artist.name}
                                    </Typography>
                                </Link>
                            </div>
                            {/* OPEN SEA / EditionOptioMenu */}
                        </div>
                        <ProductTypeInfo productFormat={formatData} className="mt-[4rem] mb-[1rem]" />
                        <div className="tablet:pr-[18.7rem] laptop:pr-0">
                            <ProductTitle title={title} slug={slug} artistUsername={artist.username} isEdition={true} />
                        </div>

                        <ProductMusicDetails
                            termsAndConditionsUrl={termsAndConditionsUrl!}
                            behindTheMusicDesc={behindTheMusic!}
                            behindTheMusicTitle={display.behindTitle}
                            credits={pressing?.credits}
                            recordLabel={recordLabel!}
                        />
                        {/* AdditionalDetails?? */}
                        {/* UMG */}
                    </div>
                </div>
            </Container>
            <Container>
                <div className="my-[6rem]" />
            </Container>
            <div className="mb-[8rem]">
                <USPBanner />
            </div>
        </div>
    );
};
