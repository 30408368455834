import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { GetEditionFormatQuery, GetEditionFormatQueryVariables } from '@srnade/web/__generated__/graphql';
import { GET_EDITION_FORMAT } from '@srnade/web/services';
import { query } from 'clients/apollo';
import { HYDRATE } from 'next-redux-wrapper';
import { GlobalState } from '../global';

export enum EditionFormatStatus {
    Idle = 'Idle',
    Pending = 'Pending',
    Failed = 'Failed',
}

export interface EditionFormatState {
    editionFormat: GetEditionFormatQuery['editionFormat'] | null;
    status: EditionFormatStatus;
    error: string | null | undefined;
}

const initialState: EditionFormatState = {
    editionFormat: null,
    status: EditionFormatStatus.Idle,
    error: null,
};

interface FetchEditionFormatArgs {
    filters: GetEditionFormatQueryVariables['filters'];
    token?: string;
    accountId?: string;
}
export const fetchEditionFormat: AsyncThunk<GetEditionFormatQuery, FetchEditionFormatArgs, AsyncThunkConfig> =
    createAsyncThunk('editions/fetchEditionFormat', async (args: FetchEditionFormatArgs, thunkApi) => {
        try {
            const { filters, token, accountId } = args;
            const response = await query<GetEditionFormatQuery, GetEditionFormatQueryVariables>({
                query: GET_EDITION_FORMAT,
                fetchPolicy: 'no-cache',
                variables: {
                    filters,
                },
                ...(token &&
                    accountId && {
                        context: {
                            headers: { authorization: `Bearer ${token}`, 'account-id': accountId },
                        },
                    }),
            });
            return response.data as GetEditionFormatQuery;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    });

const editionFormatSlice = createSlice({
    name: 'editions',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setEditionFormat(_, action) {
            return {
                status: action.payload?.status ?? EditionFormatStatus.Idle,
                editionFormat: action.payload?.editionFormat ?? null,
                error: action.payload?.error ?? null,
            };
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            // Please read https://github.com/kirill-konshin/next-redux-wrapper?tab=readme-ov-file#state-reconciliation-during-hydration
            .addCase(HYDRATE, (state, action: PayloadAction<GetEditionFormatQuery, typeof HYDRATE>) => {
                state.status = EditionFormatStatus.Idle;
                state.editionFormat = action.payload.editionFormat;
                state.error = null;
            })
            .addCase(fetchEditionFormat.pending, (state) => {
                state.status = EditionFormatStatus.Pending;
            })
            .addCase(fetchEditionFormat.fulfilled, (state, action) => {
                state.status = EditionFormatStatus.Idle;
                state.editionFormat = action.payload.editionFormat;
                state.error = null;
            })
            .addCase(fetchEditionFormat.rejected, (state, action) => {
                state.status = EditionFormatStatus.Failed;
                if (action.payload instanceof Error) {
                    // For example this could be validation error handling
                    state.error = action?.payload?.message;
                } else {
                    state.error = action.error.message;
                }
            });
    },
});

export const { setEditionFormat } = editionFormatSlice.actions;
export const EditionFormatReducer = editionFormatSlice.reducer;

export const selectEditionFormatStatus = (state: GlobalState) => state.editions.status;
export const selectEditionFormat = (state: GlobalState) => state.editions.editionFormat;
export default editionFormatSlice;
