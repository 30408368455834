import { useCallback, useEffect, useRef, useState } from 'react';
import AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';
import { v4 as uuidv4 } from 'uuid';
import { FileProperties } from '@srnade/component-ui';

import { useAuth } from '@srnade/web/auth';
import { getFileExtension, getFileType } from '../utils';
import { useTranslation } from '@srnade/web/i18n/client';
import { useQueue } from 'react-use';

interface progressWithFileName {
    fileName: string;
    progress: number;
}

export interface FileUploaderState {
    disabled: boolean;
    progress: number;
    onFileDrop: (files: File, path: string) => void;
    abortFileUpload: () => void;
    uploading: boolean;
    progressWithFilename?: progressWithFileName;
}

export interface FileUploaderProps {
    onUploadStart?: () => void;
    onUploadSuccess: (file: File, properties: FileProperties) => void;
    onUploadFailed: (errorMessage: string) => void;
}

/**
 * This hooks acts as a file upload manager. It can be used to upload a file to S3.
 */
export function useFileUploader({
    onUploadStart,
    onUploadSuccess,
    onUploadFailed,
}: FileUploaderProps): FileUploaderState {
    // Obtain the id token of the current user
    const { tokenCookie, user } = useAuth();
    // File progress state
    const [progress, setProgress] = useState(0);
    //
    const [disabled, setDisabled] = useState(true);

    const [progressWithFilename, setProgressWithFilename] = useState<progressWithFileName>();

    const [uploading, setUploading] = useState(false);
    // S3 upload reference
    const currentS3Uploader = useRef<S3.ManagedUpload | null>(null);
    // We may not have the userId available when this hook gets called by a component. So we use a ref to store the value for later use.
    const userIdRef = useRef<string | null>(null);

    const { t } = useTranslation('components', { keyPrefix: 'fileUploadZone.errors' });
    const queue = useQueue<{ file: File; path: string }>();

    // When the id token changes, update the AWS SDK configuration
    useEffect(() => {
        if (!tokenCookie || !user?.id) {
            return; // no id token, no need to update the AWS SDK configuration
        }

        const region = process.env.NEXT_PUBLIC_AWS_REGION;
        const identityPoolId = process.env.NEXT_PUBLIC_AWS_IDENTITY_POOL_ID;
        const loginProvider = process.env.NEXT_PUBLIC_AWS_LOGIN_PROVIDER;

        if (!region || !identityPoolId || !loginProvider) {
            throw new Error('Missing AWS configuration in the env variables');
        }

        AWS.config.region = region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolId,
            Logins: { [loginProvider]: tokenCookie },
        });
        userIdRef.current = user.id; // set UserId value in the ref so we can use it later
        setDisabled(!tokenCookie);
    }, [tokenCookie, user]);

    /**
     * This function uploads a file to the S3 bucket using the AWS SDK. While file is uploading, it updates the progress state.
     * @param file Actual file object
     * @param path The location of the file to be uploaded
     */
    const uploadFileToS3 = async (
        fileName: string,
        bucketParams: S3.Types.PutObjectRequest,
    ): Promise<S3.ManagedUpload.SendData> => {
        const s3Client = new S3();
        const upload = s3Client.upload(bucketParams);
        currentS3Uploader.current = upload; // set the current uploader in the ref so we can use it to abort the upload process later
        upload.on('httpUploadProgress', (evt) => {
            onUploadStart?.();
            const percent = Math.round((evt.loaded * 100) / evt.total) || 0;
            setProgress(percent);
            setProgressWithFilename({ fileName: fileName, progress: percent });
        });
        return upload.promise();
    };

    /**
     * This function uploads a file to the S3 bucket using the AWS SDK.
     * If file upload fails, it updates the fileUploadFailed state.
     * If the file upload is successful, it returns the file metadata.
     * @param file actual file object
     * @param path the location of the file to be uploaded
     */
    const uploadFile = async (item: { file: File; path: string }) => {
        const { file, path } = item;
        try {
            setProgress(0);
            setUploading(true);

            const fileExtension = getFileExtension(file);
            const newFilename = `${uuidv4()}.${fileExtension}`;
            const bucketParams = {
                Body: file,
                Bucket: process.env.NEXT_PUBLIC_AWS_BUCKET_NAME as string, // bucket name
                Key: `${path}/${newFilename}`, // generate a unique file name for each file
            };
            const fileType = getFileType(file);

            setProgressWithFilename({ fileName: file.name, progress: 0 });

            // @todo based on file type upload to different locations (protect video and audio)
            // We still want to upload the main S3 with pre-signed URL.
            const { Location, Key } = await uploadFileToS3(file.name, bucketParams);
            // However, we will need to retrieve from CloudFront
            const cloudFormURI = process.env.NEXT_PUBLIC_AWS_S3_CLOUDFRONT;
            const fileURL = cloudFormURI + Key;

            const properties: FileProperties = {
                url: fileURL,
                size: file.size,
                extension: fileExtension,
                fileType: fileType,
                filename: newFilename,
                path: Key,
                mimeType: file.type,
                originalFilename: file.name,
                s3Url: Location,
            };

            onUploadSuccess(file, properties);
        } catch (error) {
            const errorMessage = t('fileUploadError');
            onUploadFailed(errorMessage);
        } finally {
            setUploading(false);
            queue.remove();
        }
    };

    const addToQueue = queue.add;
    const onFileDrop = useCallback(
        (file: File, path: string) => {
            addToQueue({ file, path });
        },
        [addToQueue],
    );

    useEffect(() => {
        if (queue.first) {
            uploadFile(queue.first);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queue.first]);

    /**
     * This functions aborts upload process for the current file.
     */
    const abortFileUpload = () => {
        if (currentS3Uploader.current) {
            currentS3Uploader.current.abort();
        }
    };

    return {
        progress,
        onFileDrop,
        abortFileUpload,
        uploading,
        progressWithFilename,
        disabled,
    };
}
