export enum ProductStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
}

export enum OriginalProductTag {
    Original = 'Original', // This isn't a real product tag, but we need at least one
}

export const ProductTagsHumanized: { [key: string]: string } = {};

export enum ProductType {
    Pressing = 'Pressing',
    Collectible = 'Collectible',
}

export enum PressingType {
    ExtendedPlay = 'ExtendedPlay',
    Single = 'Single',
    Album = 'Album',
}

export const PressingTypeHumanized: { [key: string]: string } = {
    [PressingType.ExtendedPlay]: 'EP',
    [PressingType.Single]: 'Single',
    [PressingType.Album]: 'Album',
};

export const ProductDescriptorTermsLink = 'https://serenade.co/terms';
export const ProductDescriptorTermsDescription = `Primary and secondary market purchases and trading of this non-fungible token (NFT) is subject to and governed by the Serenade Terms of Service (Serenade ToS) which are available at ${ProductDescriptorTermsLink}. By transacting with this NFT (on any market), users agree to and submit to the Serenade ToS.`;
