// Usage:
// function App() {
//     // Similar to useState but first arg is key to the value in local storage.
//     const [name, setName] = useCookie('name', 'Bob');

//     return (
//         <div>
//             <input type="text" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
//         </div>
//     );
// }
import { useCallback, useState } from 'react';
import Cookies, { CookieAttributes } from 'js-cookie';

export const useCookie = (key: string, initialValue: null | string) => {
    const getCookie = useCallback(() => Cookies.get(key) || initialValue, [initialValue, key]);
    const [storedValue, setStoredValue] = useState(getCookie);

    const setValue = useCallback(
        (value: null | string, options?: CookieAttributes) => {
            setStoredValue(value);
            if (value !== null) {
                Cookies.set(key, value, options);
            } else {
                Cookies.remove(key);
            }
        },
        [key],
    );

    return [storedValue as any, setValue];
};
