import React, { useRef } from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import { Typography, TypographyProps } from '@srnade/component-ui';
import { addUsernamePrefix } from '@srnade/component-ui';

import { ApprovedArtistsData, LIST_APPROVED_ARTISTS_NAV } from '@srnade/web/services';
import { Link } from '@srnade/web/components';

import styles from './ListArtistNames.module.scss';

const LOADING_STATE_ARRAY = Array.apply(null, Array(50));
const SHARED_TYPOGRAPHY_PROPS = {
    className: 'w-[15rem] laptop:w-[18rem] h-[2rem] cursor-pointer hover:underline hover:font-bold truncate',
    variant: 'body',
} as TypographyProps;

type OnClickDisable = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;

const onClickDisable: OnClickDisable = (event) => {
    event.preventDefault();
};

export const ListArtistNames: React.FC = () => {
    const { current: loadingPlaceholder } = useRef(
        LOADING_STATE_ARRAY.map((_, index) => (
            <Link href="" key={`ListArtistNames_${index}`} aria-disabled={true} onClick={onClickDisable}>
                <Typography {...SHARED_TYPOGRAPHY_PROPS}>
                    <Skeleton width="180px" height="20px" />
                </Typography>
            </Link>
        )),
    );
    const { data, loading } = useQuery<ApprovedArtistsData>(LIST_APPROVED_ARTISTS_NAV, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });
    const hiddenArtistsUsernames = ['keyproductions'];
    const allArtists =
        data?.findApprovedArtists?.filter((artist) => !hiddenArtistsUsernames.includes(artist.username)) ?? [];

    return (
        <div
            className={clsx(
                'flex flex-wrap auto-cols-min justify-between gap-6 laptop:h-[30rem] laptop:grid laptop:grid-cols-5 laptop:gap-x-[7.7rem] desktop:gap-x-[7.9rem] laptop:overflow-x-hidden',
                styles.scrollbar,
                {
                    'laptop:pr-[1rem]': loading ? true : allArtists.length > 45,
                },
            )}
        >
            {loading
                ? loadingPlaceholder
                : allArtists.map(({ username, name }) => (
                      <Link href={`/${addUsernamePrefix(username)}`} passHref legacyBehavior key={username}>
                          <Typography {...SHARED_TYPOGRAPHY_PROPS}>{name}</Typography>
                      </Link>
                  ))}
        </div>
    );
};
