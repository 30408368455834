import { useCallback } from 'react';
import Cookies from 'js-cookie';

import { CookieKey } from '@srnade/web/auth';
import { getStreamUrl } from '@srnade/web/utils';

export const useGetStreamUrl = () => {
    const fetchStreamUrl = useCallback(async (mediaId: string | undefined | null) => {
        if (mediaId) {
            const internalUrl = getStreamUrl(mediaId);
            const token = Cookies.get(CookieKey.Token);
            const account = Cookies.get(CookieKey.Account);
            const headers: Record<string, string> = {
                Authorization: `Bearer ${token}`,
                // @ts-ignore
                'account-id': account,
            };

            return await fetch(internalUrl, { headers });
        }

        return null;
    }, []);

    return fetchStreamUrl;
};
